import React, { useEffect, useRef, useState } from 'react';
import { Accordion, useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type { I18n } from '@aurora/shared-types/texts';
import InfoPopover from '../../common/InfoPopover/InfoPopover';
import { ToggleableState } from '../enums';
import type { FormFieldsetType, LayoutFormFieldset } from '../types';
import localStyles from './FormFieldset.module.pcss';
import Icons from '../../../icons';
import Button from '../../common/Button/Button';
import { ButtonVariant } from '../../common/Button/enums';
import Icon from '../../common/Icon/Icon';
import { IconSize, IconColor } from '../../common/Icon/enums';

interface Props<FormDataT> {
  /**
   * Id of the form.
   */
  formId: string;
  /**
   * i18n to use for the localized text.
   */
  i18n: I18n<unknown, unknown>;
  /**
   * The content of the fieldset.
   */
  children: React.ReactNode;
  /**
   * Specification to render fieldset and legend.
   */
  fieldSetSpec: FormFieldsetType<FormDataT> | LayoutFormFieldset;
  /**
   * Add specified classname to the fieldset.
   */
  className?: string;
}

interface LegendProps {
  /**
   * Id for the legend.
   */
  id: string;
  /**
   * The classname to apply to the legend.
   */
  className?: string;
  /**
   * The element to render the legend as.
   */
  as?: React.ElementType;
  /**
   * Children elements of the legend
   */
  children?: React.ReactNode;
  /**
   * The role to apply to the legend.
   */
  role?: string;
  /**
   * The aria-level to apply to the legend.
   */
  ariaLevel?: number;
}

/**
 * Renders the psudo legend for the form fieldset
 *
 * @author Adam Ayres, Willi Hyde
 */
function Legend({
  id: legendId,
  className: legendClassName,
  as: LegendComponent = 'span',
  role,
  ariaLevel,
  children
}: LegendProps): React.ReactElement {
  return (
    <LegendComponent id={legendId} className={legendClassName} role={role} aria-level={ariaLevel}>
      {children}
    </LegendComponent>
  );
}

/**
 * User defined type guard for working with FormFieldSetType params not present on the LayoutFormFieldset interface
 *
 * @param fieldSetSpec the fieldSetSpec
 */
function isFormFieldsetType<FormDataT>(
  fieldSetSpec: LayoutFormFieldset | FormFieldsetType<FormDataT>
): fieldSetSpec is FormFieldsetType<FormDataT> {
  return 'useIDAsHtmlID' in fieldSetSpec || 'ref' in fieldSetSpec;
}

/**
 *
 * Renders form fieldset and legend based on specified parameters. Fieldset and legend
 * rendered as divs to workaround browser stlying issues related to using the fieldset
 * element directly. Aria attributes are added to ensure the divs are as accessible as
 * the fieldset and legend.
 *
 * https://www.w3.org/WAI/tutorials/forms/grouping/
 *
 * @author Manish Shrestha, Adam Ayres
 */
const FormFieldset = <FormDataT extends object>({
  formId,
  i18n,
  children,
  fieldSetSpec,
  className
}: Props<FormDataT>): React.ReactElement => {
  const {
    id,
    toggleState,
    props,
    legend,
    description,
    toggleButtonProps,
    toggleCollapseProps,
    className: fieldsetClassName
  } = fieldSetSpec;

  const { disabled, className: classNameFromSpec } = props || {};

  const { hasMessage, formatMessage, loading: textLoading } = i18n;
  const [accordionState, setAccordionState] = useState<ToggleableState>(toggleState);
  const cx = useClassNameMapper(localStyles);
  const legendTextKey = `${formId}.fieldset.${id}.legend`;
  const descriptionTextKey = `${formId}.fieldset.${id}.description`;
  const infoTextKey = `${formId}.fieldset.${id}.info`;
  const isLegendSpecified = legend?.label || hasMessage(legendTextKey);
  const isDescriptionSpecified = hasMessage(descriptionTextKey);
  const isInfoSpecified = hasMessage(infoTextKey);
  const finalLegendLabel = legend?.label || formatMessage(legendTextKey);
  const classNames = [classNameFromSpec, fieldsetClassName, className];
  const uidSeed = useUIDSeed();
  const legendId = isLegendSpecified ? uidSeed('legend') : null;
  const accordionRef = useRef<HTMLDivElement>(null);

  const fieldsetId: string =
    isFormFieldsetType<FormDataT>(fieldSetSpec) && fieldSetSpec.useIDAsHtmlID
      ? id
      : uidSeed('fieldset');

  useEffect(() => {
    /**
     * It handles the focussing of the parent accordion when button is focussed using keyboard navigation
     */
    const accordionElement = accordionRef.current;

    function applyStylesOnFocus(event: FocusEvent) {
      const focusedButton = event.target as HTMLElement;

      if (focusedButton.matches('button:focus-visible')) {
        accordionElement.style.outline = 'var(--lia-outline-color) solid 3px';
        accordionElement.style.outlineOffset = '0';
      }
    }

    function removeStylesOnFocus() {
      accordionElement.style.outline = '';
      accordionElement.style.outlineOffset = '';
    }

    accordionElement?.addEventListener('focusin', applyStylesOnFocus);
    accordionElement?.addEventListener('focusout', removeStylesOnFocus);
  }, []);

  if (textLoading) {
    return null;
  }

  if (accordionState && isLegendSpecified) {
    const sectionId = `section-${id}`;
    return (
      <Accordion
        role="group"
        className={cx('lia-accordion', classNames)}
        defaultActiveKey={accordionState === ToggleableState.OPEN ? '0' : undefined}
        ref={accordionRef}
      >
        <h3 className={cx('lia-accordion-header')}>
          <Accordion.Toggle
            as={Button}
            variant={ButtonVariant.UNSTYLED}
            className={cx(
              'lia-accordion-toggle',
              {
                'lia-accordion-is-open': accordionState === ToggleableState.OPEN
              },
              {
                'lia-accordion-has-subtext': isDescriptionSpecified
              },
              toggleButtonProps?.className
            )}
            eventKey="0"
            id={fieldsetId}
            aria-disabled={disabled}
            aria-controls={sectionId}
            aria-expanded={accordionState === ToggleableState.OPEN}
            onClick={(): void => {
              setAccordionState(
                accordionState === ToggleableState.OPEN
                  ? ToggleableState.CLOSED
                  : ToggleableState.OPEN
              );
            }}
          >
            <Legend
              id={legendId}
              className={cx(legend?.className, 'lia-accordion-title')}
              as={legend?.as}
              role={legend?.role}
              ariaLevel={legend?.ariaLevel}
            >
              {finalLegendLabel}
              {isInfoSpecified && <InfoPopover infoText={formatMessage(infoTextKey)} />}
            </Legend>
            <div className={cx('lia-accordion-icon-wrap lia-g-icon-btn')}>
              <Icon
                icon={Icons.ChevronRightIcon}
                color={IconColor.GRAY_900}
                size={IconSize.PX_16}
                className={cx('lia-accordion-icon')}
              />
            </div>
            {isDescriptionSpecified && (
              <small className={cx(description?.className, 'lia-accordion-subtext')}>
                {formatMessage(descriptionTextKey)}
              </small>
            )}
          </Accordion.Toggle>
        </h3>
        <Accordion.Collapse
          eventKey="0"
          id={sectionId}
          role="region"
          aria-labelledby={fieldsetId}
          className={cx(toggleCollapseProps?.className)}
        >
          <div className={cx('lia-accordion-content')}>{children}</div>
        </Accordion.Collapse>
      </Accordion>
    );
  } else {
    return (
      <div
        id={fieldsetId}
        role="group"
        className={cx(classNames, 'lia-fieldset')}
        aria-disabled={disabled}
        aria-labelledby={legendId}
        data-testid={`fieldset-${id}`}
        ref={
          isFormFieldsetType<FormDataT>(fieldSetSpec) && fieldSetSpec.ref
            ? fieldSetSpec.ref
            : undefined
        }
      >
        {isLegendSpecified && (
          <Legend
            id={legendId}
            className={cx(legend?.className, 'lia-legend')}
            as={legend?.as}
            role={legend?.role}
            ariaLevel={legend?.ariaLevel}
          >
            {finalLegendLabel}
            {isInfoSpecified && <InfoPopover infoText={formatMessage(infoTextKey)} />}
          </Legend>
        )}
        {isDescriptionSpecified && (
          <small className={cx(description?.className, 'lia-description')}>
            {formatMessage(descriptionTextKey)}
          </small>
        )}
        {children}
      </div>
    );
  }
};

export default FormFieldset;
