import type { DataProxy, DocumentNode } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import type { OperationVariables } from '@apollo/client/core';
import type { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';
import {
  cacheQueryVariables,
  getCachedQueryVariables
} from '@aurora/shared-apollo/cacheQueryVariables';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { getLog } from '@aurora/shared-utils/log';
import { userHoverCardDefaultProps } from './extendedOverlayTrigger/HoverCardHelper';
import VariableDiffer from '../helpers/graphql/VariableDiffer/VariableDiffer';
import { ItemType } from '../types/enums';
import mergeVariantProps from './common/ItemView/ItemViewDefaultProps';
import type { ItemViewTypeAndProps, ItemViewVariant } from './entities/types';
import { getParentFrameId } from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import type { ApolloQueryContext } from '@aurora/shared-types/apollo/tracing';

const log = getLog(module);

/**
 * Options for the entity view query.
 */
export interface EntityViewQueryHookOptions<TData = unknown, TVariables = OperationVariables>
  extends QueryHookOptions<TData, TVariables> {
  /**
   * A cache key to be used for lookup of stored query variables.  If not specified, query variables will not be stored.
   */
  cacheKey?: string;
}

/**
 * A function which merges stored query variables for dynamic views.
 * @param query
 * @param variables
 * @param cacheKey
 * @param skip Skips storing the query variables.
 */
function useEntityViewVariablesAndDefaults<TVariables>(
  query: DocumentNode,
  variables: TVariables,
  cacheKey?: string,
  skip?: boolean
): TVariables {
  const client = useApolloClient();
  const filteredVariables: TVariables = VariableDiffer.scopeToQuery<TVariables>(query, variables);
  const doSkip = skip || !cacheKey;
  cacheQueryVariables<TVariables>(client, cacheKey, filteredVariables, doSkip);
  return filteredVariables;
}

/**
 * If hovar card is enabled, method will update the fetch props to get more data.
 *
 * @param type Type of entity
 * @param mergedProps
 */
function getUpdatedFetchProps(type, mergedProps) {
  if (type === ItemType.USER) {
    const { useHoverCard } = mergedProps;
    return useHoverCard ? { ...mergedProps, ...userHoverCardDefaultProps } : mergedProps;
  } else {
    return mergedProps;
  }
}

/**
 * A hook which optionally stores the variables passed to the query in cache, allowing later retrieval for use in direct
 * cache operations, and delegates to Apollo's 'useQuery' hook.
 *
 * @param module The module of the component where the query is being used.
 * @param type The Entity type in case an empty view variant is provided.
 * @param viewVariant A view variant.  If null, defaults to the view's default type (STANDARD).  If not, props are merged.
 * @param query The query.
 * @param options The query options.
 */
export default function useEntityViewQuery<
  Type extends ItemType,
  TData = unknown,
  TVariables = OperationVariables
>(
  module: NodeModule | string,
  type: Type,
  viewVariant,
  query: DocumentNode,
  options?: EntityViewQueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const { skip, cacheKey = null, variables } = options ?? {};
  const mergedProps = mergeVariantProps<Type, TVariables>(type, viewVariant, variables);
  const fetchProps = getUpdatedFetchProps(type, mergedProps);
  const filteredVariables = useEntityViewVariablesAndDefaults<TVariables>(
    query,
    fetchProps,
    cacheKey,
    skip || !cacheKey
  );
  const parentFrameId = getParentFrameId();
  const context: ApolloQueryContext = { parentFrameId };

  return useQueryWithTracing<TData, TVariables>(module, query, {
    ...options,
    ...context,
    variables: filteredVariables
  });
}

/**
 * Get the stored variables for a given query.  Used for direct cache operations.
 *
 * @param cache an ApolloCache instance.
 * @param type
 * @param viewVariant
 * @param query The parsed query.
 * @param cacheKey one or more cache keys to be used for lookup of stored query variables.
 * @param filterDefaultValues? If true, remove any variables passed to the query that have default values.  This is use
 * to match query signatures in the Apollo cache, where variables with default values are excluded.
 */
function getCachedEntityViewVariables<Type extends ItemType, TVariables>(
  cache: DataProxy,
  type: Type,
  viewVariant: ItemViewTypeAndProps<Type, ItemViewVariant<Type>>,
  query: DocumentNode,
  cacheKey: string,
  filterDefaultValues?: boolean
): TVariables {
  const cachedProps = getCachedQueryVariables<TVariables>(cache, cacheKey) ?? {};
  const mergedProps = mergeVariantProps<Type, TVariables>(
    type,
    viewVariant,
    cachedProps as TVariables
  );
  const variables = filterDefaultValues
    ? VariableDiffer.filterDefaults(query, mergedProps)
    : VariableDiffer.fillDefaults<TVariables>(query, mergedProps);
  if (!variables) {
    log.warn('Unable to find variables for cache key %s', cacheKey);
  }
  return variables;
}

export { getCachedEntityViewVariables, useEntityViewVariablesAndDefaults };
